import React from 'react';
import { ReactComponent as RatingBadge } from '../../images/icons/rating_badge.svg';
import styles from './RatingShort.module.scss';
import classNames from 'classnames';
const RatingShort = ({ value, title }) => {
  if (!(value > 0)) return null;
  return (
    <span className={styles.wrapper} title={title}>
      <RatingBadge className={classNames(styles.star, { [styles.noReviews]: !(value > 0) })} /> <span>{value}</span>
    </span>
  );
};

export default RatingShort;
